import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt, faEnvelope, faPhone, faFax, faGlobeAsia } from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/layout/Layout'
import Image from '../components/Image'
import Card from '../components/Card'

import ContactInfo from '../data/contact-data.yaml'

const Contact = () => (
  <Layout>

    <section id="contact">
      <div className="container mx-auto p-8 pt-12 text-center">
        <h1 className="md:text-4xl text-3xl font-semibold tracking-tight justify-center">{ContactInfo.title}</h1>
        <p className="md:text-lg font-light text-center mt-4 space-y-4">{ContactInfo.overview}</p>
      </div>
    </section>
    
    <section id="map" className="py-2">
      <div className="container flex md:flex-row flex-col mx-auto p-8">
        <div className="md:flex md:flex-col grid grid-cols-2 md:w-1/4 w-full space-y-4">

          <div className="md:flex flex-col">
            <div className="w-32 md:mx-auto md:mt-0 mt-4 mb-1 logo-highlight">
              <Link to="/">
                <Image src="logo.PNG" alt="Vizirail" disableLightBox={true} />
              </Link>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-row space-x-2">
              <div className="flex-col">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
              <div className="text-md font-medium text-black">
                <span className="flex">{ContactInfo.address.level}</span>
                <span className="flex">{ContactInfo.address.street}</span>
                <span className="flex">{ContactInfo.address.suburb}</span>
                <span className="flex">{ContactInfo.address.state}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-row space-x-2">
              <div className="flex-col">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <div className="text-md font-medium text-black">
                <span className="flex">{ContactInfo.mail.box}</span>
                <span className="flex">{ContactInfo.mail.suburb}</span>
                <span className="flex">{ContactInfo.mail.country}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <span className="space-x-2">
              <FontAwesomeIcon icon={faEnvelope} />
              <a href={`mailto:${ContactInfo.email}`}>
                {ContactInfo.email}
              </a>
            </span>
            <span className="space-x-2">
              <FontAwesomeIcon icon={faPhone} />
              <a href={`tel:${ContactInfo.telephone}`}>
                {ContactInfo.telephone}
              </a>
            </span>
            <span className="space-x-2">
              <FontAwesomeIcon icon={faGlobeAsia} />
              <a href={`tel:${ContactInfo.international}`}>
                {ContactInfo.international}
              </a>
            </span>
            <span className="space-x-2">
              <FontAwesomeIcon icon={faLinkedin} />
              <a href={ContactInfo.linkedin}>Linkedin</a>
            </span>
          </div>
        </div>

        <div className="flex md:flex-col flex-row md:w-3/4 w-auto md:mt-0 mt-8">
          <div className="google-map-code border border-gray-400 w-full bg-gray-200">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.3079010282477!2d138.57044321627336!3d-34.92381418201417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0ced1eed47b1f%3A0x6f7b709b2b401d47!2sViziRail!5e0!3m2!1sen!2sau!4v1604286213808!5m2!1sen!2sau"
              width="100%"
              height="480"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>

      </div>
    </section>

    <section id="contact" className="md:py-12 py-10 bg-gray-100 border border-gray-300 shadow-inner hidden">
      <div className="container mx-auto md:px-8 px-4">
        <Card className="flex flex-row mx-auto md:p-8 text-center">
          <p className="md:text-lg text-md text-black font-bold w-full my-auto">
            CONTACT FORM HERE
          </p>
        </Card>
      </div>
    </section>

  </Layout>
)
export default Contact